.chatzy-container {
  &.sidebar-overlap {

    .chatzy-main,
    .message-input,
    .contact-details {
      width: calc(100% - 500px) !important;
    }
  }

  .main-nav {
    .logo-warpper {
      text-align: center;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba($white, 0.2);
    }

    .sidebar-main {
      height: calc(100vh - 186px);
      display: inline-flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;

      .sidebar-top,
      .sidebar-bottom {
        .btn-light {
          &.active {
            background-color: $primary-color !important;

            i {
              color: $white;
            }
          }
        }

        li {
          text-align: center;
        }
      }
    }
  }

  .chatzy-left-sidebar {
    .search-form:before {
      top: 24px;
      left: 14px;
    }

    .contact-list-tab,
    .settings-tab,
    .recent-default,
    .status-tab {
      position: absolute;
      min-height: calc(100vh);
      overflow: auto;
      overflow-x: hidden;
      overflow-y: auto;
      top: 0;
      bottom: 0;
      z-index: 0;
      width: 420px;
      min-width: 420px;
      padding: $left-sidebar-spacing;

      &.active {
        opacity: 1;
        z-index: 3;
        //animation: fadeInLeft 300ms ease-in-out;
      }

      .my-status-box {
        border-bottom: 1px solid $border-color;
        padding-bottom: 40px;

        .status-content {
          .d-flex {
            .user-status {
              &::before {
                display: none;
              }
            }

            .img-status {
              background-color: transparent;
              width: fit-content;
              border-radius: 50%;
              margin-right: 15px;
              position: relative;

              .bg-size {
                width: 50px;
                height: 50px;
                border-radius: 50%;
              }

              img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
              }

              .upload-img {
                border-radius: 50%;
                width: 20px;
                height: 20px;
                background-color: $primary-color;
                position: absolute;
                bottom: -8px;
                right: 0;
                color: $white;

                &:before {
                  content: "\f067";
                  font-family: "Poppins", sans-serif;
                  font-size: 11px;
                  padding: 6px;
                }

                input {
                  background-color: gray;
                  border-radius: 50%;
                  width: 100%;
                  height: 100%;
                  opacity: 0;
                  position: absolute;
                  top: 0;
                }
              }
            }

            .flex-grow-1 {
              h3 {
                text-transform: capitalize;
                margin-bottom: 5px;
              }

              p {
                margin-bottom: 0;
                text-transform: capitalize;
                letter-spacing: 1px;
              }
            }
          }
        }
      }

      .contact-status-box {
        padding-top: 20px;

        h3 {
          padding: 15px 25px;
          color: $primary-color;
          background-color: rgba($primary-color, 0.1);
          text-transform: capitalize;
          margin-bottom: 20px;
          border-radius: 15px;
        }

        .status-content {
          margin-bottom: 20px;
          border-bottom: 1px solid $border-color;
          padding-bottom: 15px;
          z-index: 0;
          position: relative;

          &.active {
            z-index: 9;
          }

          &:last-child {
            margin-bottom: 0;
          }

          ul {
            display: flex;
            align-items: center;

            li {
              position: relative;

              +li {
                position: unset;
              }

              h4 {
                margin-bottom: 5px;
                text-transform: capitalize;
                color: $dark-color;
                font-weight: 600;
              }

              .img-status {
                width: 54px;
                height: 54px;
                border-radius: 50%;
                position: relative;
                border: 2px solid $white;
                display: block;
                overflow: hidden;

                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              h5 {
                margin-bottom: 0;
                text-transform: capitalize;
              }

              &:first-child {
                margin-right: 15px;
              }
            }
          }
        }

        .accordion {
          .accordion-item {
            border: none;

            .accordion-button {
              padding: 0;
              font-weight: 600;

              &:after {
                width: 20px;
                height: 20px;
                color: $dark-color;
              }
            }

            .accordion-body {
              padding-left: 0;
              padding-right: 0;

              .status-content {
                .user-status {
                  &::before {
                    background-color: transparent;
                  }
                }
              }
            }
          }
        }

        .accordion-button:not(.collapsed) {
          background-color: transparent;
          box-shadow: none;
          color: $dark-color;
        }
      }
    }

    .recent-default {
      position: relative;
      z-index: 0;
      padding: 0 !important;

      &.active {
        z-index: 999;
      }

      .recent {
        position: fixed;
        width: 420px;
        top: 0;
        background-color: $white;
        padding-top: 10px;
        z-index: 1;

        .template-title {
          padding: 0 $sidebar-spacing;
          border-bottom: 1px solid rgba($muted-font, 0.2);

          .img-text {
            .chat-box {
              .profile {
                height: 70px;
                width: 70px;
              }
            }
          }
        }

        .recent-slider {
          padding: 10px 15px 0px;

          .recent-box {
            position: relative;
            display: flex;
            align-items: center;
            margin-left: 10px;

            &:hover {
              .recent-profile {
                box-shadow: -1px 10px 16px -10px rgba(102, 102, 102, 1);
                transition: all 0.3s ease;
              }
            }

            .dot-btn {
              position: absolute;
              bottom: 25px;
              right: 15px;
            }

            .recent-profile {
              height: 66px;
              width: 66px;
              border-radius: $radious50;
              position: relative;
              transition: all 0.3s ease;
              border: 3px solid $white;
              outline: 2px solid $primary-color;

              img {
                border-radius: 50%;
              }

              h6 {
                position: absolute;
                color: $dark-color;
                bottom: -30px;
                left: 15px;
                text-transform: capitalize;
                max-width: 60px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }
          }
        }

        &~.chat {
          margin-top: 300px;
          height: calc(100vh - 242px);
          min-height: calc(100vh - 242px);
          border-top: 1px solid rgba($muted-font, 0.2);
        }
      }

      .chat {
        overflow: auto;
        overflow-x: hidden;
        overflow-y: auto;

        >.template-tab {
          padding: 20px 30px 30px;

          &.tab-sm {
            >.nav-tabs {
              padding-bottom: 20px;
            }

            &.tab-radious {
              .nav-tabs {
                width: 80%;
                margin: 0 auto;

                >.nav-item .nav-link {
                  border-radius: $radious5;
                }
              }
            }
          }
        }

        .template-title {
          padding: $sidebar-spacing $sidebar-spacing 0 $sidebar-spacing;
        }

        .template-title {
          .d-flex {
            .flex-grow-1 {
              text-align: right !important;
            }
          }
        }
      }
    }

    .contact-list-tab,
    .settings-tab {
      .template-title {
        .d-flex {
          .flex-grow-1 {
            text-align: right;
          }
        }
      }
    }
  }
}

body {
  &.dark-sidebar {
    .landing-header {
      .dropdown-menu {
        background-color: $dark;
        border: 1px solid $dark;

        li {
          a {
            color: $white;
          }
        }
      }

      .navbar-collapse {
        .navbar-nav {
          .nav-item {
            .nav-link {
              color: $white;
            }
          }
        }
      }
    }
  }
}

body {
  &.dark-sidebar {
    .inner-page {
      background-color: $light;
    }
  }
}

body {
  &.dark-sidebar {
    .error-main {
      background-color: $white;
    }
  }
}

.chatzy-left-sidebar {
  background: linear-gradient(180deg, rgba($primary-color, 1) 0%, rgba($primary-light, 1) 100%);
  [class*="-tab"] {
    .search-form .form-group .icon-close {
      top: 10px !important;
    }
  }
}

.chatzy-left-sidebar {
  .search-form .form-group .icon-close {
    top: 11px !important;
  }
}

.search-form {
  width: calc(100% - 40px);
  left: 20px;
  height: calc(100% - 30px);
  position: absolute;
  top: 12px;

  &:before {
    position: absolute;
    left: 31px;
    top: 20px;
    content: "\e610";
    color: $dark-color;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 14px;
  }

  .form-group {
    height: 100%;
    width: 100%;
    margin-top: 0;

    input {
      border: 1px solid $border-color;
      padding: 0 42px;
      height: 60px;
      width: 100%;
      border-radius: 15px;
      font-size: 16px;
      background-color: $white;
      border: 1px solid rgba($muted-font, 0.1);

      &:focus {
        outline: none;
      }
    }

    .icon-close {
      position: absolute;
      padding: 12px;
      right: 10px;
      top: 6px;
      cursor: pointer;

      svg {
        height: 16px;
        vertical-align: -4px;
      }
    }
  }
}

.search {
  &~.search-form {
    transform: scale(0);
    transition: all 0.3s ease;

    &.open {
      transform: scale(1);
      transition: all 0.3s ease;
    }
  }
}

.contact-list-tab {
  li .chat-box .date-status {
    top: 10px;
  }

  .chat-main {
    margin-right: -40px;
    padding-right: 40px;
    height: 100vh;
  }
}

.contact-list-tab {
  .search-form {
    top: -15px;
    left: 0px;
    position: absolute;
    height: 75px;
    width: 100%;

    &:before {
      position: absolute;
      left: 25px;
      content: "\e610";
      color: $dark-color;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 14px;
    }
  }
}

// swipe menu
.menu-trigger {
  cursor: pointer;
}

.menu-active {
  .chatzy-container .chatzy-main {
    width: calc(100% - 850px);
    transition: all 0.7s;
  }

}

.menu-trigger {
  cursor: pointer;
}

.sidebar-active {
  .sidebar-toggle {
    .main-nav.on {
      display: unset;

      ~.chatzy-main .message-input {
        width: calc(100% - 1012px);
      }
    }
  }

  .chatzy-container .chatzy-main {
    width: calc(100% - 920px);
    transition: all 0.7s;

    &.small-sidebar {
      width: calc(100% - 618px);
      transition: all 0.7s;

      .message-input {
        width: calc(100% - 560px);
      }

      .messages {
        .contact-details {
          width: calc(100vw - 520px);
        }
      }
    }
  }

  .sidebar-toggle .main-nav.on~.chatzy-main .contact-details {
    width: calc(100vw - 920px);
    transition: all 0.3s ease;
  }

  .sidebar-toggle .main-nav.on~.chatzy-main.small-sidebar {
    .contact-details {
      width: calc(100vw - 618px);
      transition: all 0.3s ease;
    }
  }

  .sidebar-toggle {
    .main-nav {
      &.on {
        ~.chatzy-main {
          &.small-sidebar {
            .message-input {
              width: calc(100% - 659px);
              margin: 0 20px;
            }
          }
        }
      }
    }
  }
}

.menu-active {
  .chatzy-container .chatzy-main {
    width: calc(100% - 948px);
    transition: all 0.7s;
  }

  .chatzy-main {
    .message-input {
      width: calc(100% - 850px);
      transition: all 0.9s;
    }

    .messages {
      .contact-details {
        width: 50vw !important;
        transition: all 0.9s ease;
      }
    }
  }
}

.sidebar-active {
  .sidebar-toggle {
    .main-nav {
      &:not(.on) {
        &~.chatzy-main {
          &:not(.small-sidebar) {
            width: calc(100% - 822px);

            .contact-details {
              width: calc(100vw - 855px);
            }

            .message-input {
              width: calc(100% - 890px);
            }
          }
        }
      }
    }
  }
}

.sidebar-toggle {
  .main-nav {
    transition: all 0.3s ease;
    transform: translateX(-100px);
    animation: fadeInLeft 300ms ease-in-out;
    min-width: 0;
    width: 0;

    &:not(.on) {
      &~.chatzy-main {
        &.small-sidebar {
          width: calc(100% - 520px);
          transition: all 0.3s ease;
        }
      }
    }

    li {
      transform: translateX(-100px);
      transition: transform 0.5s ease;
    }

    &.on {
      transition: all 0.3s ease;
      transform: translateX(0px);
      min-width: 100px;
      animation: fadeInLeft 300ms ease-in-out;
      background-color: $primary-color;
      display: block;

      &~.chatzy-main {
        .contact-details {
          width: calc(100vw - 534px);
          transition: all 0.3s ease;
          z-index: 999;
        }

        .message-input {
          width: calc(100% - 578px);
          margin: 0 30px;
        }
      }

      li {
        transform: translateX(0);
        transition: transform 0.5s ease;
      }
    }
  }
}

.dynemic-sidebar {
  .chat-main {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    .pined {
      cursor: pointer;
      order: -1;
      margin-bottom: 0 !important;
      border-bottom: 1px solid rgba($muted-font, 0.15);
    }
  }
}

// settings
.profile-box {
  padding: 10px 12px;
  background-color: $primary-color;
  border-radius: 10px;

  h4 {
    color: $white;
  }

  h6 {
    color: $white;
  }

  .open {
    .details {
      display: none;

      &.edit {
        display: block;
        animation: pulse 300ms ease-in-out;
      }
    }

    .edit-btn {
      &:before {
        content: "\f0c7";
        font-family: "Poppins", sans-serif;
      }

      svg {
        display: none;
      }
    }
  }

  .details {
    padding-left: 0px;
    padding-top: 13px;
    animation: pulse 300ms ease-in-out;

    &.edit {
      padding-top: 0;
      padding-right: 20px;
      display: none;
    }
  }

  h6 {
    padding-top: 6px;
  }
}

.setting-block {
  padding: $sidebar-spacing;
  border-top: 1px solid $border-color;
  margin: 0 -30px;
  transition: all 0.4s ease-in-out;

  &:hover {
    box-shadow: 0px 0px 10px 6px rgba(46, 35, 94, 0.07);
  }

  h4 {
    padding-top: 8px;
  }

  .block {
    position: absolute;
    height: calc(100% - 120px);
    padding: $sidebar-spacing $sidebar-spacing 0 $sidebar-spacing;
    width: 100%;
    top: 85px;
    left: 0;
    bottom: 0;
    background-color: $white;
    opacity: 0;
    z-index: -1;
    border-top: 1px solid $border-color;

    .help {
      padding-top: 20px;

      >li {
        padding-top: 12px;
        padding-bottom: 12px;
        border-top: 1px dashed $border-color;

        .switch-list {
          >li {
            padding-left: 0;
            display: flex !important;
            gap: 10px;
          }
        }

        a {
          color: rgba($dark-color, 0.8);
          font-weight: 600;
        }
      }
    }

    &.open {
      opacity: 1;
      z-index: 1;
      animation: fadeInLeft 300ms ease-in-out;

      .template-according {
        padding-bottom: 20px;

        .card-header {
          padding: 15px;
          border-radius: 10px;
          border: 1px dashed rgba($black, 0.1);

          a {
            padding-left: 30px;
          }

          i {
            top: 10px;
          }
        }

        .card-body {
          padding: 15px;
          border-radius: 10px;
          border: 1px dashed rgba($black, 0.1);
        }
      }
    }
  }
}

.setting-block {

  .next,
  .previous {
    &:hover {
      background-color: $primary-color !important;

      svg {
        stroke: $white !important;
      }
    }
  }
}

// profile portfolio //
.portfolio-padding {
  padding-bottom: 40px;
}

.portfolio-section {
  .isotopeSelector {
    margin-bottom: 30px;
  }

  .article-title {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    color: white;
    transition: all 0.5s ease;
    text-align: center;
    float: left;
    font-size: 18px;
    line-height: 24px;

    a {
      color: white;
    }
  }

  .isotopeSelector {
    .overlay {
      position: relative;
      overflow: hidden;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .isotopeSelector {
    img {
      transition: all 0.4s ease;
    }

    .overlay-background {
      transform: scale(0);
      transition: all 0.4s ease;
      border-radius: 15px;

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        background: $primary-color;
        padding: 5px;
        border-radius: 100%;
        transform: translate(-50%, -50%);
        color: $white;
        font-size: 12px;
        display: none;
        transition: all 0.4s ease;
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
      }
    }

    &:hover {
      img {
        transform: scale(1.2) rotate(2deg);
        transition: all 0.4s ease;
      }

      .overlay-background {
        transform: scale(1);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.1);
        opacity: 1;
        z-index: 1;
        transition: all 0.3s linear;

        i {
          display: flex;
          transition: all 0.3s ease;
        }
      }
    }
  }

  .border-portfolio {
    overflow: hidden;
    border-radius: 15px;
  }

  #form1 {
    padding-bottom: 20px;
  }

  .filter-button {
    padding: 15px 24px;
    font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1920 - 320)));
    border: none;
    background-color: $white;
    margin: 0 5px;
    line-height: 1;

    &.active {
      background-color: $primary-color;
      color: $white;
    }
  }

  &.fullwidth-portfolio {
    .isotopeSelector {
      padding-left: 7px;
      padding-right: 7px;
      margin-bottom: 14px;
    }
  }
}

.filter-section {
  .filter-container {
    text-align: center;
    padding: 0 0 20px;

    ul {
      &.filter {
        >li {
          padding: 0 25px;

          >a {
            color: $primary-color;
            font-size: 20px;
            line-height: 40px;

            &:hover,
            &:focus {
              text-decoration: none;
              color: $primary-color;
              outline: none;
            }
          }

          &.active {
            background-color: $primary-color;

            a {
              color: $white;
            }
          }

          span {
            color: $border-color2;
            font-size: 20px;
            display: inline-block;
            margin: 0 5px;
          }
        }
      }
    }

    .filter {
      >li {
        padding: 0;
        margin: 0;
      }
    }
  }

  .title1 {
    .title-inner1 {
      margin-top: -5px;
    }
  }

  h1 {
    text-align: center;
  }
}

.integratin {
  margin-top: 20px;

  li {
    position: relative;
    padding-top: 13px;
    padding-bottom: 13px;
    border-bottom: 1px solid $border-color;

    &:last-child {
      border-bottom: none;
    }

    .media-right {
      svg {
        height: 20px;
        cursor: pointer;
        color: $gray;
      }
    }

    .media-left {
      width: 100%;

      a {
        font-size: 16px;

        h5 {
          display: inline-block;
          font-size: 16px;
        }
      }
    }

    .fb {
      color: $facebook;

      h5 {
        color: $facebook;
      }
    }

    .insta {
      color: $instagram;

      h5 {
        color: $instagram;
      }
    }

    .twi {
      color: $twiter;

      h5 {
        color: $twiter;
      }
    }

    .slc {
      color: $slack;

      h5 {
        color: $slack;
      }
    }

    .ggl {
      color: $google;

      h5 {
        color: $google;
      }
    }

    p {
      padding-left: 20px;
      padding-top: 7px;
    }

    .profile {
      height: 20px;
      width: 20px;
      margin-top: 3px;
    }
  }
}

.wallpaper {
  margin-top: 15px;

  li {
    cursor: pointer;
    height: 30px;
    width: 30px;
    background-color: rgba($primary-color, 0.1);
    display: inline-block !important;
    background-size: auto !important;
  }
}

.app-list-ul {
  text-align: center;

  .apps-toggle {
    position: absolute;
    background-color: $primary-color;
    cursor: pointer;
    top: 12%;
    left: -16%;
    height: 30px;
    width: 30px;
    border-radius: 10px;

    svg {
      vertical-align: -13px;
      stroke: $white;
    }
  }

  li {
    margin-bottom: 20px;

    &.right-sidebar-profile {
      height: 90px;
      border-bottom: 1px solid rgba($muted-font, 0.1);

      .profile {
        margin: 6px auto;
        height: 60px;
        width: 60px;
      }
    }

    .btn-sm {
      height: 50px;
      width: 50px;

      svg {
        height: 22px;
        width: 22px;
        transition: all 0.4s ease-in-out;
      }
    }
  }
}

.chat-content {
  .message-input {
    .emojis-main {
      .typing-section {
        background-color: $white;
        border: 1px solid $white;
      }

      button {
        svg {
          stroke: $white;
        }
      }
    }
  }
}

.mobile-sidebar,
.mobile-back {
  display: none;
}

// chat-cont setting //

ul.chat-cont-setting {
  background-color: $white;
  box-shadow: 0 0 10px 5px rgba($gray1, 1);
  padding: 20px 15px;
  width: fit-content;
  text-align: right;
  position: fixed;
  border-radius: 6px;
  left: 18%;
  bottom: 12%;
  z-index: 1;
  display: none;
  transition: all 0.5s ease;

  &.open {
    display: block;
  }

  li {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 4px;

      span {
        padding: 5px;
        line-height: 1;
        text-transform: capitalize;
        font-size: 13px;
      }

      .icon-chat-cont {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;

        svg {
          width: 20px;
          height: 20px;
          fill: $white;
        }
      }
    }
  }
}

.msg-chat-modal {
  .chat-msg-search {
    margin-bottom: 20px;

    .input-group {
      align-items: center;
      background-color: $border-color;
      border: 1px solid $border-color;

      .form-control {
        border: none;
      }

      .input-group-append {
        padding: 0 15px;
      }
    }

    span {
      border: none;
      background-color: transparent;
    }
  }

  .chat-main {
    height: 350px;
    overflow: auto;

    li {
      margin-left: 0;
      margin-right: 0;

      .chat-box {
        .details {
          padding-left: 70px;
        }
      }
    }
  }
}
