.questions {
  display: block;

  .question {
    display: block;
    position: relative;
    text-align: center;
    border: 3px solid #D6D5CD;
    background-color: #fff;
    border-radius: 27px;
    margin-bottom: 16px;
    padding: 10px 0 10px 16px;
    cursor: pointer;

    span {
      font-size: 20px;
      font-weight: 500;
    }

    .icon {
      margin-right: 8px;
    }

    &:hover {
      border-color: #E5DBB1;
    }

    &.selected {
      border-color: #E5DBB1;
      background-color: #FFF3C5;

      .checkmark {
        display: block;
        img {
          width: 28px;
          height: 28px;
        }
      }
    }

    .checkmark {
      display: none;
      top: 10px;
      right: 8px;
      position: absolute;
    }
  }
}