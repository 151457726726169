.onboarding-stories {
  .header {
    padding: 0;

    h1 {
      font-size: 24px;
      font-weight: bold;
    }

    p {
      font-size: 16px;
    }
  }

  .featured {
    min-height: 209px;
  }

}