// ganral css //
.section-pb-space {
  padding-bottom: 100px;
}

.section-pt-space {
  padding-top: 100px;
}

.section-py-space {
  padding-top: 100px;
  padding-bottom: 100px;
}

// landing btn starat //
.btn {
  border: none;
}

// landing header //
.navbar-light .navbar-brand {
  margin-right: 0;
}

header {
  position: absolute;
  top: 25px;
  width: 100%;
  z-index: 9;

  .landing-header {
    .navbar {
      flex-direction: unset;
      padding: 6px 0;
    }

    .navbar-collapse {
      width: 100%;

      .navbar-nav {
        flex-direction: unset;
        align-items: center;
        height: 100%;

        .nav-item {
          display: inline-block;
          position: relative;
          margin-left: 30px;
          font-weight: 600;
          text-transform: uppercase;
          font-size: calc(13px + (16 - 13) * ((100vw - 320px) / (1920 - 320)));
          transition: all 0.3s ease;

          &.active {
            .nav-link {
              color: $primary-color;
            }
          }

          .nav-link {
            color: rgba($black, 0.7);
          }
        }
      }
    }

    &.fixed {
      position: fixed;
      width: 100%;
      background-color: $primary-color;
      z-index: 9;
      padding: 5px 20px;
      max-width: 1600px;
      border-radius: 20px;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 0 0 30px rgba($primary-color, 0.5);
      transition: all 0.3s ease;

      .main-menu .navbar-nav>li>a {
        color: $white !important;
      }

      .main-menu {
        .navbar {
          .navbar-brand {
            .logo-block {
              .for-light {
                display: none;
              }

              .for-dark {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

header {
  .landing-header {
    .navbar-collapse {
      ul {
        li {
          a {
            color: $white;
            font-weight: 700;
            font-size: 15px;
          }
        }

        .active {
          a {
            color: $white;
          }
        }
      }
    }
  }
}

.landing-page {
  .landing-header {
    .navbar-light {
      .navbar-nav {
        .active {
          >.nav-link {
            color: $white;
          }
        }

        .nav-link {
          color: $white;
        }
      }
    }
  }
}

header {
  &.template-landing {
    .landing-header {
      .navbar-collapse {
        .navbar-nav {
          .nav-item {
            .nav-link {
              color: $white;
            }
          }
        }
      }
    }
  }
}

// footer start //
footer {
  background-color: rgba($primary-color, 0.1);
  overflow: hidden;
  position: relative;
  border-top: 1px solid #ddf1ff;

  .footer-main {
    padding-top: calc(30px + (70 - 30) * ((100vw - 320px) / (1920 - 320)));
    padding-bottom: calc(12px + (70 - 12) * ((100vw - 320px) / (1920 - 320)));
  }

  h3 {
    margin-bottom: 25px;
    font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
    position: relative;
  }

  p {
    font-size: calc(13px + (15 - 13) * ((100vw - 320px) / (1920 - 320)));
    color: rgba($dark-color, 0.9);
  }

  .copyright {
    p {
      color: rgba($dark-color, 0.6);
    }
  }

  span {
    font-size: 18px;
    color: $danger-color;
    line-height: 0;
    padding-left: 12px;
  }

  .logo {
    margin-bottom: 15px;
  }

  .links {
    li {
      display: block;
      position: relative;
      padding-left: 20px;
      transition: all 0.3s ease;

      &:hover {
        padding-left: 22px;
        transition: all 0.3s ease;

        a {
          color: $primary-color;
          transition: all 0.3s ease;

          &:before {
            left: 3px;
            color: $primary-color;
            transition: all 0.1s ease;
          }
        }
      }

      a {
        font-size: calc(14px + (15 - 14) * ((100vw - 320px) / (1920 - 320)));
        color: $dark-color;
        font-weight: 500;

        &:before {
          content: "\e649";
          font-family: "Poppins", sans-serif;
          font-size: 11px;
          position: absolute;
          left: 0;
          top: 4px;
          transition: all 0.3s ease;
          font-weight: 700;
        }
      }

      &+li {
        margin-top: 12px;
      }
    }
  }

  .copyright-footer {
    padding-top: 1.1rem;
    border-top: 1px solid rgba($dark-color, 0.1);
  }

  .foot-aps-btn {
    border-radius: 10px;
    filter: drop-shadow(0px 5px 15px rgba(29, 159, 250, 0.35));
    background-image: linear-gradient(145deg, #08cfa4 0%, #01aa85 100%);
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }

    i {
      color: $white;
      margin-right: 15px;
      font-size: 30px;
    }

    span {
      h6 {
        color: $white;
        font-family: $font-1;
        font-weight: 400;
        text-transform: capitalize;
        margin-bottom: 5px;
      }

      h4 {
        color: $white;
        font-family: $font-1;
        font-weight: 700;
        text-transform: capitalize;
        line-height: 1;
      }
    }
  }

  .footer-back-block {
    .inner1 {
      position: absolute;
      top: -15%;
      right: 10%;
      transform: rotate(96deg);
      animation: animatone 30s reverse infinite;
    }

    .inner2 {
      position: absolute;
      bottom: 10%;
      left: -80px;
      animation: slidemain 40s linear infinite;
    }
  }

  .footer-mobile-title {
    display: none;
  }
}

// index page scss//
.index-main {
  .landing-page-header {
    background-size: cover;
    background-repeat: no-repeat;
    scroll-behavior: smooth;

    .navbar-nav {
      width: 100% !important;
      justify-content: end !important;
    }

    .home-content {
      padding-top: 40px;

      h2 {
        font-size: calc(22px + (48 - 22) * ((100vw - 320px) / (1920 - 320)));
        font-weight: 700;
        text-transform: uppercase;
        color: $white;
        margin-bottom: 8px;
        line-height: 1.4;
        font-family: $font-inter;
        width: 90%;

        span {
          font-family: $font-inter;
          color: $primary-color;
          font-weight: 700;
          font-size: calc(22px + (48 - 22) * ((100vw - 320px) / (1920 - 320)));
        }
      }

      p {
        font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1920 - 320)));
        color: rgba($white, 0.7);
        margin-bottom: calc(16px + (70 - 16) * ((100vw - 320px) / (1920 - 320)));
        font-family: $font-inter;
      }

      >div {
        display: flex;
        align-items: center;
        gap: 10px;

        .button-doc {
          background-color: #ffa941;
          color: $white;
          text-transform: uppercase;
          padding: calc(8px + (14 - 8) * ((100vw - 320px) / (1920 - 320))) calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
          font-family: $font-inter;

          svg {
            height: 24px;
            width: 24px;
            vertical-align: -6px;
            margin-right: 6px;
          }
        }
      }

    }

    .home-img {
      position: relative;

      img {
        &:nth-child(2) {
          position: absolute;
          left: 24%;
          top: 15%;
          width: 130px;
          animation: bounce-effect 2s infinite ease-in;
        }

        &:nth-child(3) {
          position: absolute;
          bottom: 8%;
          right: -2%;
          animation: bounce-effect 3s infinite ease-in;
        }
      }
    }
  }

  .client-messenger-box {
    overflow: hidden;

    .client-images {
      display: flex;
      align-items: center;

      img {
        animation: slide-left 50s linear infinite;
      }
    }
  }
}
