.onboarding .question {
  display: flex;
  align-items: center;

  .panda {
    scale: 120%;
    img {
      width: 66px;
      height: 84px;
    }
  }

  .question {
    font-size: 20px !important;
    font-weight: 600;
    text-align: left;
  }

  .text {
    width: calc(100% - 68px);
    margin-left: auto;
    background-color: #fff;
    border-radius: 12px;
    padding: 12px;
    margin-left: 26px; 
    width: 300px;
    position: relative;
    z-index: 1;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    

    &:after {
      content: '';
      position: absolute;
      left: -10.5px;
      top: 50%;
      transform: rotate(45deg);
      width: 21px;
      height: 21px;
      border: 12px solid transparent;
      box-shadow: -6px 8px 12px rgba(0, 0, 0, 0.1);
      border-top: 0;
      border-right: 0;
      margin-top: -12px;
      background-color: #fff;
    }
  }

}
