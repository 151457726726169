.onboarding {
  padding: 0;
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-height: 100vh;
  position: relative;
  padding-bottom: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;

  .onboarding-progress-bar {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
    margin-top: 24px;
    align-self: flex-start;
  }

  .cta-button {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
    align-self: flex-end;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }

  .onboarding-content {
    margin: auto 0;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
  }

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .header {
    margin-top: 32px;
    padding: 0;
    h1 {
      line-height: 150%;
      font-size: 32px;
      font-weight: 900;
    }
  }

  .subheader {
    h2 {
      padding: 24px 0;
      margin: 0;
      font-size: 18px;
    }
  }

  .btn {
    padding-top: 0;
    padding-bottom: 0;
  }

  .btn-primary {
    width: 75%;
    font-size: 20px;
    font-weight: 700;
    padding: 8px 0;
    border: none;
    border-radius: 12px;
    color: #fff;
    background-color: #000 !important;
    border-bottom: 6px solid gray !important;
    border-color: gray !important;
    text-transform: unset !important;
  }

  .btn-secondary {
    width: 75%;
    font-weight: bold;
    padding: 8px 0;
    border: none;
    border-radius: 12px;
    color: #000;
    background-color: #FFF3C5;
    border-bottom: 6px solid gray;
  }

  .two-buttons {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin-top: 24px;
  
    button {
      margin-left: 12px;
    }

    :first-child {
      margin-left: 0;
    }

    button {
      width: 45%;
    }
  }

  .carousel {
    .carousel-indicators {
      margin-bottom: 0 !important;
      bottom: 8px;
    }

    .carousel-indicators [data-bs-target] {
      background-color: black;
    }
    
    .review {
      border: 3px solid transparent;
      border-radius: 16px;
      background-color: #fff;
      text-align: left;
    }

    .review {
      .who {
        margin-top: 6px;
        margin-bottom: 6px;
      }

      .title {
        margin-bottom: 6px;
      }
    }
  }
}

.row > .onboarding-standard-section {
  width: 375px;

  @media screen and (width < 375px) {
    width: 100%;
  }
}