.app-security {
  &.onboarding {
    display: block;
    flex-direction: unset;
    min-height: 550px;
    padding-bottom: 0;
  }

  .onboarding-progress-bar {
    z-index: 100000;
  }

  .app-personalizin-frame {
    z-index: -1;
    
    .animation {
      height: 345px;
      margin-top: -50px;
      width: 100%;
    }
  }

  .progress-meter {
    position: relative;
    text-align: left;

    .progress {
      padding-left: 0;
      padding-right: 0;
      margin: 12px;
      margin-left: 0;
      margin-right: 0;

      .progress-bar {
        background-color: #000;
      }
    }

    p {
      color: #000 !important;
      margin-bottom: 0 !important;
    }

    .progress-number {
      position: absolute;
      right: 0px;
      bottom: 0;
      text-align: right;
      font-weight: bold;
      padding-right: 0;
      color: #000;
    }

    p {
      padding-left: 0;
    }
  }

  .app-personalizin-frame {
    height: 269px;

    .hero-image {
      width: 322px;
      margin: 0 auto;
    }
  }
}