.onboarding-layout {
  background-color: #AEAAF2;
  background: linear-gradient(180deg, rgba(#AEAAF2,1) 0%, rgba(#E9D3ED,1) 100%);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  
  .outlet {
    width: 100%;
    min-height: 700px;
    margin: auto;
    //border: 1px dotted red;
    display: flex;
    justify-content: center;
  }
}

.centered-text {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  color: #555;
  font-size: 9px;
}

html {
//  background-color: #AEAAF2 !important;
}