@import "utils/variables.scss";
@import "style";
html {
  &.style1 {
    $primary-color: #AEAAF2;
    $primary-light: #E9D3ED;
    $secondary-color: #D7D5F9;
    $secondary-light: #F4E9F6;
    $gray1: #f4effa;
    $dark: #021617;
    $light: #053235;
    $light-dark: #042022;
    @import "style";
  }
  &.style2 {
    $primary-color: #FF7B95;
    $primary-light: #FFB3B3;
    $secondary-color: #FFBDCA;
    $secondary-light: #FFD9D9;
    $gray1: #e1f1ee;
    $dark: #031a28;
    $light: #102a44;
    $light-dark: #04273c;
    @import "style";
  }
  &.style3 {
    $primary-color: #FFA366;
    $primary-light: #F5D998;
    $secondary-color: #FFD1B2;
    $secondary-light: #FAECCB;
    $gray1: #e4e7ef;
    $dark: #111726;
    $light: #242f4a;
    $light-dark: #192136;
    @import "style";
  }
  &.style4 {
    $primary-color: #9BB0FD;
    $primary-light: #9FDAF9;
    $secondary-color: #CDD7FE;
    $secondary-light: #CFEDFC;
    $gray1: #daf6f5;
    $dark: #12080d;
    $light: #2e1522;
    $light-dark: #22111a;
    @import "style";
  }
  &.style5 {
    $primary-color: #C1F3A5;
    $primary-light: #A7F1DD;
    $secondary-color: #E0F9D2;
    $secondary-light: #D3F8EE;
    $gray1: #faebf0;
    $dark: #0d0914;
    $light: #271c39;
    $light-dark: #130d1d;
    @import "style";
  }
}
