.onboarding-progress-bar {
  .back, .next {
    cursor: pointer;
  }

  .next {
    font-size: 18px;
    margin-top: -5px;
  }

  .progress {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    background-color: #000;
    height: 5px;
    margin-top: 6px;
    margin-left: 30px;
    margin-right: 30px;

    .progress-bar {
      background-color: #FFF3C5;
      border-radius: 5px;
    }
  }
}