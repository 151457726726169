.app-showcase {
  .phone-habits {
    position: relative;

    .add-a-routine {
      width: 160px;
      height: auto;
      position: absolute;
      left: 20px;
      top: 350px;
    }

    .cards {
      width: 156px;
      height: auto;
      position: absolute;
      right: 20px;
      top: 240px;
    }
  }
}