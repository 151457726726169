.onboarding-finish {
  padding-top: 180px;
  background: transparent url("./assets/confetti.png") no-repeat 50% 0;

  .row > img {
    padding-right: 0;
    padding-left: 0;
  }

  .cheering {
    height: 264px;
    img {
      width: 132px;
      height: 132px;
    }
  }

  .subheader {
    margin-bottom: 24px;
  }
}

