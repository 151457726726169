.onboarding-achievements {
  min-height: 900px;

  .row > img {
    padding-right: 0;
    padding-left: 0;
  }

  .achievements-container {
    img {
      height: 456px;
      width: 343px;
    }
  }

}