.onboarding-start {

  &.onboarding {
    min-height: 750px;
  }

  .featured {
    padding: 16px 0;
  }

  .cta-button {
    margin: 32px 0;
  }

  .disclaimer {
    padding: 0 80px 12px 80px;
    margin-top: 24px;

    p {
      font-size: 11px;
    }

    strong {
      cursor: pointer;
    }
  }

  .panda-image {
    width: 160px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  
  .header {
    margin-top: 0;
  }

  .reviews {
    margin-bottom: 16px;

    .review {
      padding: 8px;
    }
  }

  .start-animation {
    position: relative;
    .lazy-load-placeholder {
      position: absolute;
      top: 43px;
      left: 53px;
      width: 148px;
      opacity: 0;
      transition: opacity 0.3s;

      &.show {
        opacity: 0.7
      }
    }
  }
}