.payment .plan {
  font-size: 16px;
  border: 3px solid #D6D5CD;
  background-color: #fff;
  cursor: pointer;
  border-radius: 16px;
  position: relative;
  padding: 7px 18px;
  margin-bottom: 16px;
  text-align: left;

  p {
    margin: 3px 0;

    &.description {
      color: #000 !important;
    }
  }
  
  .badge {
    margin-left: 24px;
    font-size: 14px;
    background-color: #ffc803 !important;
    font-weight: 400;
    border-radius: 100px;
  }

  &.selected {
    border-color: #E5DBB1;
    background-color: #FFF3C5;

    .checkmark {
      display: block;
      position: absolute;
      top: 14px;
      right: 14px;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .name {
    font-weight: 700;
    margin-bottom: 16px;
  }

  .checkmark {
    display: none;
    position: absolute;
    top: calc(50% - 14px);
    right: 28px;
  }

}