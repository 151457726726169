.app-showcase {
  .close-button {
    z-index: 10;
  }
  
  .phone {
    min-height: 408px;
    img {
      width: 240px;
      height: auto;
    }
  }

  .description {
    text-align: left;
    font-size: 24px;
    padding: 20px;
  } 
}