/*======= Button-color css starts  ======= */
.btn {
	border-radius: $btn-radious;

	&.btn-sm {
		padding: 10px 30px;
	}
}

// btn--color//
@each $btn-name, $btn-color in (primary, $primary-color),
(success, $success-color),
(danger, $danger-color),
(light, $light-color),
(warning, $warning-color) {
	.btn-#{$btn-name} {
		background-color: $btn-color;
		border-color: $btn-color;
		color: $white;
		transition: all 0.3s ease;

		&.disabled,
		&:disabled {
			background-color: $btn-color !important;
			border-color: $btn-color !important;
			transition: all 0.3s ease;
		}

		&:hover,
		&:focus,
		&:active,
		&.active {
			background-color: rgba($primary-color, 1);
			transition: all 0.3s ease;
			outline: none !important;
			color: $white !important;
		}

		&:focus {
			outline: none !important;
			transition: all 0.3s ease;
		}
	}

	.btn-outline-#{$btn-name} {
		background-color: rgba($btn-color, 0.15) !important;
		color: $btn-color;
		border: none !important;
		transition: all 0.3s ease;

		@if($btn-name =="light") {
			color: $dark-color !important;
			background-color: $light-color !important;
		}

		&.disabled,
		&:disabled {
			background-color: rgba($btn-color, 0.10) !important;
			color: $btn-color;
			border: none !important;
			transition: all 0.3s ease;
		}

		&:hover,
		&:focus,
		&:active,
		&.active {
			background-color: rgba($btn-color, 0.25) !important;
			color: $btn-color;
			border: none !important;
			transition: all 0.3s ease;
		}

		&:focus {
			transition: all 0.3s ease;
		}
	}
}

.btn-light {
	color: $dark-color !important;

	&:focus,
	&:active,
	&.active {
		box-shadow: 0 0 3px 2px $light-color;
	}
}

// Icon buttons
.icon-btn {
	height: 42px;
	width: 42px;
	border-radius: $radious100;
	font-weight: 800;
	align-items: center;
	justify-content: center;
	display: inline-flex;
	border: 0 !important;

	i {
		font-size: 16px;
	}

	svg {
		width: 16px;
		height: 16px;
		stroke-width: $icon-stroke-width;
		vertical-align: middle;
	}

	&.btn-xl {
		height: 60px;
		width: 60px;
		padding: 18px;

		i {
			font-size: 24px;
		}

		svg {
			height: 24px;
			width: 24px;
		}
	}

	&.btn-sm {
		height: 34px;
		width: 34px;
		display: inline-flex;
	}

	&.btn-xs {
		height: 30px;
		width: 30px;
		padding: 8.5px;

		svg {
			height: 12px;
			width: 12px;
		}
	}

	@each $btn-name, $btn-color in (primary, $primary-color),
	(success, $success-color),
	(danger, $danger-color),
	(light, $light-color),
	(warning, $warning-color) {
		&.btn-outline-#{$btn-name} {
			background-color: rgba($btn-color, 0.15) !important;
			color: $btn-color !important;
			border: none !important;
			transition: all 0.3s ease;

			@if($btn-name =="light") {
				color: $dark-color !important;
				background-color: $light-color !important;
			}

			&.disabled,
			&:disabled {
				background-color: rgba($btn-color, 0.10) !important;
				border: none !important;
				transition: all 0.3s ease;
			}

			&:hover,
			&:focus,
			&:active,
			&.active {
				background-color: rgba($btn-color, 0.25) !important;
				border: none !important;
				transition: all 0.3s ease;
				text-decoration: none;
			}

			&.active {
				background-color: rgba($btn-color, 1) !important;
				color: $white !important;

				svg {
					stroke: $white !important;
				}
			}

			&:focus {
				box-shadow: 0 0 3px 2px rgba($btn-color, 0.15);
				transition: all 0.3s ease;
			}
		}
	}
}

// Header Icon
.header-icon {
	padding: 15px 0;
	margin-top: 20px;
	width: 100px;
	position: relative;
	display: flex;
	justify-content: center;
	transition: all .4s ease-in-out;

	svg {
		width: 28px;
		height: 28px;
		transition: all .4s ease-in-out;
	}

	.header-fill-icon {
		display: none;
		transition: all .4s ease-in-out;
	}

	&:hover {
		.header-fill-icon {
			display: block;
		}

		.header-stroke-icon {
			display: none;
		}

		&::after {
			opacity: 1;
			visibility: visible;
		}

		&::before {
			width: 100%;
		}
	}

	&.active {
		.header-fill-icon {
			display: block;
		}

		.header-stroke-icon {
			display: none;
		}

		&::after {
			opacity: 1;
			visibility: visible;
		}

		&::before {
			width: 100%;
		}
	}

	&::before {
		content: '';
		position: absolute;
		width: 0;
		height: 100%;
		top: 0;
		left: 0;
		background-color: rgba($white, 0.2);
		transition: all .4s ease-in-out;
	}

	&:after {
		content: '';
		position: absolute;
		width: 5px;
		height: 100%;
		top: 0;
		left: 0;
		background-color: $white;
		opacity: 0;
		visibility: hidden;
		transition: all .4s ease-in-out;
	}
}

.dot-btn {
	position: relative;
	display: inline-block;

	&:before {
		content: "";
		height: 9px;
		width: 9px;
		position: absolute;
		top: -1px;
		right: 1px;
		border-radius: 100%;
		z-index: 1;
		border: 1px solid $white;
	}

	&.grow {
		&:before {
			border: none;
			height: 7px;
			width: 7px;
		}

		&:after {
			content: "";
			height: 19px;
			width: 18px;
			display: inline-block;
			border-radius: 100%;
			top: -7px;
			right: -4.2px;
			position: absolute;
		}
	}

	@each $dot-name, $dot-color in (primary, $primary-color),
	(success, $success-color),
	(danger, $danger-color),
	(light, $light-color),
	(warning, $warning-color) {
		&.dot-#{$dot-name} {
			&:before {
				background-color: $dot-color !important;

				@if($dot-name =="light") {
					background-color: $dark-color !important;
				}
			}

			&.grow {
				&:after {
					border: 5px solid $dot-color;
					animation: grow 1s ease-out infinite;
				}

				@if($dot-name =="light") {
					&:after {
						border: 5px solid $dark-color;
					}
				}
			}

		}
	}
}

@keyframes grow {

	0% {
		transform: scale(0.1);
		opacity: .1;
	}

	33% {
		transform: scale(0.5);
		opacity: .3;
	}

	66% {
		transform: scale(0.8);
		opacity: .5;
	}

	100% {
		transform: scale(1);
		opacity: 0;
	}
}

// Group buttons
.template-btn-group {
	a {
		&+a {
			margin-left: 5px;
			border-radius: 15px;

			&.btn-sm {
				border-radius: 10px;
			}
		}

		&:first-child {
			border-radius: 15px 15px 0;

			&.btn-sm {
				border-radius: 10px 10px 0;
			}
		}

		&:last-child {
			border-radius: 15px 15px 15px 0;

			&.btn-sm {
				border-radius: 10px 10px 10px 0;
			}
		}

	}
}

.profile {
	width: 54px;
	height: 54px;
	display: inline-block;
	border-radius: $radious50;
	position: relative;
	margin-right: 15px;

	img {
		border-radius: 50%;
	}

	span {
		font-size: 22px;
		position: absolute;
		top: 50%;
		left: 50%;
		margin-right: -50%;
		transform: translate(-50%, -50%);
		color: $white;
	}

	&.busy,
	&.online,
	&.offline,
	&.unreachable {
		&:before {
			content: "";
			position: absolute;
			height: 13px;
			width: 13px;
			border-radius: 100%;
			border: 2px solid $white;
			right: 3px;
			bottom: 2px;
		}
	}

	&.unreachable {
		&:before {
			background-color: $warning-color;
		}
	}

	&.online {
		&:before {
			background-color: $primary-color;
		}
	}

	&.busy {
		&:before {
			background-color: $danger-color;
		}
	}

	&.offline {
		&:before {
			background-color: $gray;
		}
	}
}

// wave effect
.button-effect {
	display: inline-flex;
	overflow: hidden;
	position: relative;

	&:hover {
		cursor: pointer;
	}
}


