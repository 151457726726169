.greet-panda {
  h2 {
    font-size: 20px !important;
  }

  .text {
    background-color: #fff;
    border-radius: 12px;
    padding: 24px 12px;
    width: 300px;
    position: relative;
    z-index: 1;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 48px;
    margin-left: auto;
    margin-right: auto;
    

    &:after {
      content: '';
      position: absolute;
      bottom: -11px;
      left: calc(50% - 11px);
      transform: rotate(45deg);
      width: 21px;
      height: 21px;
      border: 12px solid transparent;
      box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.1);
      border-left: 0;
      border-bottom: 0;
      margin-top: -12px;
      background-color: #fff;
    }
  }

  .panda {
    position: relative;
    padding-bottom: 16px;

    .panda-image {
      img {
        height: 160px;
      }
    }

    .panda-shadow {
      position: absolute;
      bottom: 0;
      left: calc(50% - 100px / 2);
    }
  }
}