.onboarding-payment.variant2 {
  padding: 0 20px;
  position: relative;
  padding-bottom: 0;
  display: block;
  
  .header {
    padding: 0;

    h1 {
      width: 320px;
      margin: 0 auto;
      font-weight: 700;
      font-size: 24px;
    }

  }

  .hero {
    img {
      width: 390px;
      margin: 0 auto;
    }
    margin-bottom: 0
  }

  .clouds {
    position: sticky;
    margin-top: -100px;
    height: 100px;
    width: 100%;
    background: transparent url("./assets/clouds.png") -200px 0 repeat-x;

    z-index: 200;
  }

  &.first-page {

    .cta-section {
      background-color: #fffef5;
    }

    .clouds {
      margin-top: -100px;
    }

    .plans {
      position: sticky;
      img {
        width: 375px;
        margin: 0 auto;
      }

      background-color: #fffef5;
      margin: -32px auto 0 auto;
      padding-bottom: 32px;
      z-index: 201;
    }
  }

  &.second-page {
    .header {
      margin: 72px auto 48px auto;
      width: 300px;
    }

    .bell {
      height: 246px;
      img {
        width: 246px;
        margin: 0 auto;
      }
    }

    .cancel-info {
      margin-top: -60px;

      p {
        background: transparent url("./assets/tick.png") 0 0 no-repeat;
        background-size: 24px 24px;
        width: 346px;
        margin: 0 auto;
        color: #000;
        font-weight: bold;
        font-size: 16px;
       line-height: 24px;
      }
    }
  }

  &.third-page {
    padding-top: 32px;
    .close-icon {
      top: 16px;
    }

    .header {
      background-color: #fffef5;
      margin-top: 0;
      h1 {
        margin: 0 auto 0 auto;
        width: 380px;
      }
    }

    .stripe-plans {
      background-color: #fffef5;
      .stripe-plans-inner {
        width: 380px;
        margin: 0 auto;

        .plan {
          .name {
            margin-bottom: 8px;
          }
        }
      }
    }

    .cta-section {
      background-color: #fffef5;

      p {
        margin-bottom: 0;
        color: #000;
      }
    }

    .additional-info {
      background-color: #fffef5;
      .additional-info-inner {
        width: 380px;
        margin: 0 auto;

        .companies-image {
          width: 380px;
          margin-bottom: 32px;
        }

        p {
          color: #000;
        }

        .disclaimer {
          margin-top: 32px;
        }
      }
    }
  }

  .cta-section {
    min-height: 8px;
    padding: 20px 0;

    .cta-section-inner {
      width: 360px;
      margin: 0 auto;

      img {
        width: 360px;
      }
    }

    .purchase-button {
      display: flex;
      align-items: center;
      justify-content: center;

      &.btn-secondary {
        background-color: #3f327a;
        border-bottom: 6px solid #322666;

        &:hover {
          color: #fff;
        }
      }
      color: #fff;
      font-weight: bold;
      font-size: 20px;
      line-height: 20px;
      border: none;
      width: 100%;
      padding: 12px 0 8px 0;
      border-radius: 12px;
      margin: 20px 0;

      .spinner-border {
        width: 20px;
        height: 20px;
      }
    }

    .no-payment {
      font-weight: bold;
    }

    .btn-secondary {
      background-color: #fff;
      &:hover {
        color: #000;
      }
    }
  }

  .close-icon {
    position: absolute;
    top: -48px;
    left: calc(50% - 180px);
    cursor: pointer;

    img {
      width: 24px;
      height: 24px;
    }
  }
}